import {helpEmail, mailto} from '~/data/mercury'
import MWDSLink from '~/mercuryWebCompat/design-system/MWDSLink'
import {MWDSTextDiv} from '~/mercuryWebCompat/design-system/MWDSText'
import {externalLinks} from '~/routing/external-links'
import MercuryLogo from './_images/mercury-currentColor.svg'
import styles from './styles.module.css'

export function ErrorBoundaryFallback() {
  return (
    <div className={styles.errorPageWrapper}>
      <MercuryLogo />
      <MWDSTextDiv size="title-hero" className={styles.errorHeading}>
        Something isn’t right.
      </MWDSTextDiv>
      <MWDSTextDiv size="body-16">
        We ran into an unexpected error.
        <br />
        <br />
        Please try refreshing, or contact
        <br />
        <MWDSLink underline="onHover" color="blue" href={mailto(helpEmail)}>
          {helpEmail}
        </MWDSLink>{' '}
        if this continues.
      </MWDSTextDiv>
      <MWDSTextDiv size="body-large" className={styles.upsell}>
        Existing customer? You can still access your account{' '}
        <MWDSLink href={externalLinks.auth.login} color="blue">
          here
        </MWDSLink>
        .
      </MWDSTextDiv>
    </div>
  )
}
